import { showDangerMessage } from 'modal-helper';
import { t } from '../../i18n';

export default (message) => showDangerMessage({
  title: t('javascript.error_page.error'),
  message,
  iconClosable: true,
  icon: false,
  dataLayer: {
    error_title: t('javascript.error_page.error'),
    error_message: message,
    error_key: message,
    error_code: 'E0x012',
  },
});
