import { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { showTopLevelNavigation } from 'global-config';
import { useSeoEnabled } from 'Seo/helpers';
import { withErrorBoundary } from 'bv-error-boundary';
import withLazyLoad from 'bv-lazy-load';
import TabsMenu from './tabs_menu';
import ErrorBoundaryFallback from './error_boundary_fallback';

const LazySeo = withLazyLoad()('seo');

const ContentLayout = ({ showTabs, children, fetching }) => {
  const [childrenLoaded, setChildrenLoaded] = useState(false);
  const seoEnabled = useSeoEnabled();

  const handleLoad = () => {
    if (children.length) setChildrenLoaded(true);
  };

  useEffect(() => {
    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  useEffect(() => {
    if (children.length) handleLoad();

    return () => {
      setChildrenLoaded(false);
    };
  }, [children]);

  return (
    <div className="react-contents">
      {showTabs && showTopLevelNavigation && <TabsMenu />}
      {children}
      {seoEnabled
        && ((children.length || fetching) ? (
          <div className={classNames({
            invisible: (children.length && !childrenLoaded) || fetching,
          })}
          >
            <LazySeo />
          </div>
        ) : (
          <LazySeo />
        ))}
    </div>
  );
};

ContentLayout.propTypes = {
  showTabs: PropTypes.bool,
  fetching: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ContentLayout.defaultProps = {
  showTabs: false,
  fetching: false,
};

export default withErrorBoundary(ContentLayout, {
  FallbackComponent: ErrorBoundaryFallback,
});
