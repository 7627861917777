import ModalApp from './components/modal_app';

import reducer from './reducers';
import { addModal, removeModal } from './actions';

import modalTypes from './modal_types';

const { store, addReducers, addActionDispatchers } = window.reduxState;

addReducers({
  modal: reducer,
});

addActionDispatchers('modal', { removeModal });

const helpers = {};

modalTypes.forEach((type) => {
  helpers[`show${type}Message`] = (opts = {}) => (
    Promise.resolve(store.dispatch(addModal(
      { ...opts, [type.toLowerCase()]: true, modalType: type.toLowerCase() },
    )))
  );
});

export const {
  showSuccessMessage,
  showInfoMessage,
  showWarningMessage,
  showDangerMessage,
  showDefaultMessage,
} = helpers;

export default ModalApp;
