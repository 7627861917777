import PropTypes from 'prop-types';

const SkeletonColumns = ({ cols, colName }) => {
  if (colName) return <div className={`skeleton__content ${colName}`} />;

  return cols === 1 ? (
    <div className="skeleton__content" />
  ) : (
    <div className="skeleton__content-wrapper">
      {[...Array(cols)].map(() => (
        <div className="skeleton__content" />
      ))}
    </div>
  );
};

SkeletonColumns.defaultProps = {
  cols: 1,
  colName: '',
};

SkeletonColumns.propTypes = {
  cols: PropTypes.number,
  colName: PropTypes.string,
};

export default SkeletonColumns;
