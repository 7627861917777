import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'underscore';
import classnames from 'classnames';
import Skeleton from '../skeletons';
import WebpWrapper from './webp_wrapper';

const Image = ({
  className,
  src,
  webpSrc,
  imageAlt,
  onLoad,
  onError,
  fallbackSrc,
  scrollableRef,
  webp,
  isLazyImage,
  type,
  ...rest
}) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const ref = useRef();

  const handleLoadImageSuccess = () => {
    setLoaded(true);
    onLoad();
  };

  const handleLoadImageError = () => {
    setError(true);
    onError();
  };
  return (
    <>
      <WebpWrapper webp={webp} src={src} webpSrc={webpSrc}>
        <img
          loading={isLazyImage ? 'lazy' : null}
          src={(!error ? src : fallbackSrc) || fallbackSrc}
          alt={imageAlt}
          ref={ref}
          onLoad={handleLoadImageSuccess}
          onError={handleLoadImageError}
          className={classnames(className, 'img', {
            'img-lazy': isLazyImage,
            'img-loaded': loaded && !error,
            'img-error': error,
            'img-hide': error && !fallbackSrc,
            [`img-type-${type}`]: !!type,
          })}
          {...rest}
        />
      </WebpWrapper>
      {type && !loaded && !error && (
        <Skeleton hasHeader={false} skeletonType="full-wrapper" />
      )}
    </>
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  webpSrc: PropTypes.string,
  className: PropTypes.string,
  onLoad: PropTypes.func,
  onError: PropTypes.func,
  fallbackSrc: PropTypes.string,
  scrollableRef: PropTypes.instanceOf(Object),
  imageAlt: PropTypes.string,
  webp: PropTypes.bool,
  isLazyImage: PropTypes.bool,
  type: PropTypes.string,
};

Image.defaultProps = {
  webpSrc: null,
  className: '',
  onLoad: noop,
  onError: noop,
  fallbackSrc: '',
  scrollableRef: null,
  imageAlt: '',
  webp: false,
  isLazyImage: true,
  type: null,
};

export default Image;
