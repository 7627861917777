import { find } from 'underscore';
import reduxStore from '../redux/store';

const outcome = (store, outcomeId) => store.getState().outcomes[outcomeId];

const market = (store, outcomeId) => (
  find(store.getState().markets, (m) => find(m.o, (o) => o === outcomeId)) || {}
);

const enhancedOutcomeOffer = (store, outcomeId, opts = {}) => {
  // if some offer was passed in explicitly use that
  // this is used with signup-optin, the offer won't be active at the time of adding it
  if (opts.offer && opts.offer.enhancedOutcomes[outcomeId]) {
    return opts.offer;
  }
  // find offer with an outcomeId that's active
  const offer = find(
    (store.getState().enhanedOutcomeOffers || { offers: [] }).offers,
    (o) => !!o.enhancedOutcomes[outcomeId] && o.enhancedOutcomes[outcomeId].active,
  );
  if (!offer) {
    return null;
  }
  // offer already expired, non-time-limited offers come with 0
  if (offer.endDateTime && offer.endDateTime < (new Date()).getTime() / 1000) {
    return null;
  }
  return offer;
};

const add = (store, outcomeId, opts = {}) => {
  const selectedOutcome = outcome(store, outcomeId);
  const offer = enhancedOutcomeOffer(store, outcomeId, opts);

  if (!selectedOutcome) {
    return;
  }

  const addOpts = {
    click_source: opts.clickSource,
    price_text_formatted: selectedOutcome.pr,
    enhancedOutcome: (offer?.enhancedOutcomes[outcomeId] || null),
    forceNormalBetslip: !!opts.forceNormalBetslip,
  };

  import('sportsbook-betslip-thunks').then((thunks) => {
    thunks.addToBetslip(selectedOutcome.id, addOpts)(
      store.dispatch,
      store.getState,
    );
  });
};

const remove = (store, outcomeId) => {
  const selectedOutcome = outcome(store, outcomeId);

  if (!selectedOutcome) {
    return;
  }

  import('sportsbook-betslip-thunks').then((thunks) => {
    thunks.removeFromBetslip(selectedOutcome.id)(
      store.dispatch,
      store.getState,
    );
  });
};

const addMultiple = (outcomeIds) => (
  import('sportsbook-betslip-thunks').then((thunks) => {
    thunks.addMultipleToBetslip(outcomeIds)(
      reduxStore.dispatch,
      reduxStore.getState,
    );
  })
);

// TODO: Some of these seem to be unused. Evaluate if we still need them, otherwise remove.
export {
  outcome,
  market,
  enhancedOutcomeOffer,
  add,
  remove,
  addMultiple,
};
