import { getCookie } from 'bv';
import useFeature from './use_feature';

export default () => {
  const [oneAuthEnabled, oneAuthResolved] = useFeature('one_auth');
  const [oneAuthRailsMigrationEnabled, oneAuthRailsMigrationResolved] = useFeature('one_auth_rails_migration');

  if (getCookie('one_auth_testing')) return [true, true];

  return [
    oneAuthEnabled || oneAuthRailsMigrationEnabled,
    oneAuthResolved && oneAuthRailsMigrationResolved,
  ];
};
