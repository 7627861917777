import { noop } from 'underscore';
import { seoEnabled } from 'Seo/helpers';

export default () => {
  const enabled = seoEnabled();
  const path = window.location.pathname;

  if (!enabled) return;

  import('../../../app/seo/index.js').then(() => {
    if (path === window.location.pathname) {
      window.reduxState.store.actionDispatchers.seo.setPrerenderReady();
    }
  }).catch(noop);
};
